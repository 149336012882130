.pc-navbar {
  .navbar-container {
    height: 900px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background-size: cover;
    background-position: center;
    transition: background-image 1s ease-in-out;
    .navbar-config {
      padding-top: 10px;
      .menu-names {
        color: #fff;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;
        text-decoration: none;
      }
      .user-box {
        margin-left: 5px;
        display: flex;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50px;
        }
      }
      .logo {
        width: 53.421px;
        height: 58.775px;
        flex-shrink: 0;
      }
      .main-homepage-text {
        color: #fff;
        text-align: center;
        font-family: "Montserrat";
        font-size: 64px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
        margin-top: 162px;
      }
      .search-box {
        position: relative;
      }
      .search-input {
        display: inline-flex;
        padding: 8px 10px;
        justify-content: center;
        align-items: center;
        border-radius: var(--numbers-radius-base, 8px);
        border: 1px solid #fff;
        background: linear-gradient(
          90deg,
          rgba(232, 243, 255, 0.5) 0%,
          rgba(241, 252, 255, 0.5) 100%
        );
        backdrop-filter: blur(2px);
        width: 332px;
        height: 64px;
        font-size: 16px;
        position: relative;
      }
      .search-icon {
        width: 36px;
        height: 36px;
        position: absolute;
        bottom: -15px;
        margin-left: -20px;
      }
    }
  }
  .search-input {
    &::placeholder {
      color: white;
    }
  }
  @media (max-width: 1200px) {
    .navbar-container {
      height: 700px;
    }
  }
  @media (max-width: 768px) {
    .navbar-container {
      height: 500px;
      .navbar-config {
        .main-homepage-text {
          color: #fff;
          text-align: center;
          font-family: Montserrat;
          font-size: 26px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
      }
    }
  }
  @media (max-width: 480px) {
    .navbar-container {
      height: 300px;
      .navbar-config {
        .main-homepage-text {
          color: #fff;
          text-align: center;
          font-family: Montserrat;
          font-size: 26px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
      }
    }
  }

  .other-navbar {
    background: #f8fbff;
    .navbar-config {
      padding-top: 10px;
      .menu-names {
        color: #1e1e1e;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;
        text-decoration: none;
      }
      .user-box {
        margin-left: 5px;
        display: flex;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50px;
        }
      }
      .logo {
        width: 53.421px;
        height: 58.775px;
        flex-shrink: 0;
      }
      .main-homepage-text {
        color: #fff;
        text-align: center;
        font-family: "Montserrat";
        font-size: 64px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
        margin-top: 162px;
      }
      .search-box {
        position: relative;
      }
      .search-input {
        display: inline-flex;
        padding: 8px 10px;
        justify-content: center;
        align-items: center;
        border-radius: var(--numbers-radius-base, 8px);
        border: 1px solid #fff;
        background: linear-gradient(
          90deg,
          rgba(232, 243, 255, 0.5) 0%,
          rgba(241, 252, 255, 0.5) 100%
        );
        backdrop-filter: blur(2px);
        width: 332px;
        height: 64px;
        font-size: 16px;
        position: relative;
      }
      .search-icon {
        width: 36px;
        height: 36px;
        position: absolute;
        bottom: -15px;
        margin-left: -20px;
      }
    }
  }
}

.mobile-home-navbar {
  position: relative;
  width: 100%;
  height: auto;
  .navbar-frame {
    width: 100%;
    height: 32vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url("../../public/assets/images/home_hero1.png") lightgray 50% / cover
        no-repeat;
    background-size: cover;
    background-position: center;
    background-size: cover;
    background-position: center;
    .home-navbar {
      padding: 8px 0 0;
      height: auto;
      display: flex;
      flex-direction: row;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(2.5px);
      .m-container {
        flex-direction: row;
        justify-content: space-between;
        .lan-box {
          .btn-lang {
            img {
              width: 28px;
              height: 24px;
              border-radius: 2px;
            }
          }
        }
      }
    }
    .header-content {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      p {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        display: none;
      }
      .search-box {
        position: relative;
        display: none;
        .search-input {
          // display: inline-flex;
          padding: 8px 24px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #fff;
          background: linear-gradient(
            90deg,
            rgba(232, 243, 255, 0.5) 0%,
            rgba(241, 252, 255, 0.5) 100%
          );
          backdrop-filter: blur(2px);
          width: 90%;
          position: relative;
          color: #fff;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        ::placeholder {
          color: white;
          opacity: 1;
        }

        ::-ms-input-placeholder {
          color: white;
        }
        .search-icon {
          width: 36px;
          height: 36px;
          position: absolute;
          bottom: -15px;
          margin-left: -20px;
        }
      }
    }
  }
}

.mobile-other-navbar {
  position: relative;
  width: 100%;
  height: auto;
  .navbar-frame {
    .home-navbar {
      padding: 14px 0 8px;
      height: auto;
      display: flex;
      flex-direction: row;
      background-color: #f8fbff;
      .m-container {
        flex-direction: row;
        justify-content: space-between;
        .lan-box {
          .btn-lang {
            img {
              width: 28px;
              height: 24px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
}
