.products-page {
  background: #f8fbff;
  padding-bottom: 100px;
  .products-container {
    padding-top: 50px;
    position: relative;
    .no-data {
      padding: 30px 20px 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 56px;
        margin-bottom: 2px;
      }
      p {
        color: #bdbdbd;
        text-align: center;
        font-size: 26px;
        margin-top: 10px;
        font-family: Rubik;
      }
    }

    .title {
      margin-bottom: 20px;
      color: #1e1e1e;
      font-family: Rubik;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .filter-box {
      padding: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 24px;
      border: 1px solid rgba(205, 217, 232, 0.8);
      background: #fff;
      .filter {
        display: flex;
        width: 240px;
        flex-direction: column;
        span {
          display: flex;
          padding-bottom: 6px;
          color: #181d25;
          font-family: Poppins;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
        }
        select {
          padding: 10px;
          border-radius: 8px;
          border: 1px solid #e1e5ea;
          background: #fff;
          box-sizing: border-box;
          color: #181d25;
          font-family: Poppins;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
    .car-box-img {
      width: 280px;
      height: 234px;
      border-radius: 10px;
      cursor: pointer;
    }
    .car-price {
      color: #1e1e1e;
      font-family: Rubik;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
    }
    .car-title {
      color: #606e80;
      font-family: Rubik;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .car-details {
      color: #1e1e1e;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .car-details-container {
      position: relative;
      padding: 0 20px;
      &::before {
        left: 0;
        top: 0;
      }
      &::after {
        right: 0;
        top: 0;
      }
    }
    .car-details-item {
      padding: 0 10px;
      position: relative;
    }
    .car-location {
      color: #404b5a;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 14px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .car-details-container {
      &::before {
        content: "";
        width: 1px;
        height: 100%;
        background: rgba(30, 30, 30, 0.3);
        position: absolute;
      }
      &::after {
        content: "";
        width: 1px;
        height: 100%;
        background: rgba(30, 30, 30, 0.3);
        position: absolute;
      }
    }
  }

  .chosen-product {
    padding-top: 40px;
    .car-img-main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .car-left {
        width: 49%;
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
          height: 490px;
          border-radius: 16px;
        }
        div {
          margin-top: 12px;
          display: flex;
          flex-direction: row;
          img {
            cursor: pointer;
            width: 148px;
            height: 100px;
            border-radius: 8px;
            &:nth-child(even) {
              margin: 0 10px;
            }
          }
        }
      }
      .car-right {
        width: 49%;
        display: flex;
        flex-direction: column;
        .contact-form {
          display: flex;
          flex-direction: column;
          align-items: center;
          .contact-main-form {
            padding: 24px;
            width: 100%;
            height: 514px;
            display: flex;
            flex-direction: column;
            border-radius: 24px;
            border: 1px solid #d7e1ed;
            background: #fff;
            box-sizing: border-box;
            .form-box {
              margin-bottom: 25px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              .input-box {
                width: 48%;
                display: flex;
                flex-direction: column;
                span {
                  color: #181d25;
                  font-family: Inter;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 500;
                }
                div {
                  margin-top: 10px;
                  padding: 8px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  border-radius: 8px;
                  border: 1px solid #e1e5ea;
                  background: #fff;

                  input {
                    margin-left: 10px;
                    width: 90%;
                    border: none;
                    outline: none;
                    color: #929fb1;
                    font-family: Inter;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                  }
                }
              }
            }
            .form-send {
              margin-bottom: 30px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              span {
                color: #929fb1;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              button {
                display: flex;
                padding: 8px 12px;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                border: 1px solid #0056bb;
                background: #0056bb;
                box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2) inset,
                  0px 2px 0px 0px rgba(255, 255, 255, 0.16) inset;
                color: #fff;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                svg {
                  margin-left: 8px;
                }
              }
            }
          }
        }
      }
    }

    .car-detail-main {
      margin-top: 25px;
      width: 49%;
      .car-main-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        div {
          cursor: pointer;
          padding: 8px;
          display: flex;
          border-radius: 8px;
          border: 1px solid #d7e1ed;
          background: rgba(215, 225, 237, 0.2);
        }
      }
      .car_detail {
        margin-top: 24px;
        padding: 27px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 16px;
        border: 1px solid #e1e5ea;
        background: #f8fbff;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            color: rgba(30, 30, 30, 0.4);
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          p {
            margin-top: 8px;
            color: #1e1e1e;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          &.line {
            width: 1px;
            height: 46px;
            background: rgba(30, 30, 30, 0.3);
          }
        }
      }
      .info {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        span {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          margin-top: 10px;
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 27px */
        }
      }
    }

    .similar-cars-main {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      strong {
        color: #1e1e1e;
        font-family: Rubik;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .card-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .car-price {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 8px;
        }
        .car-box-img {
          width: 280px;
          height: 214px;
          border-radius: 10px;
          cursor: pointer;
        }
        .car-title {
          color: #606e80;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .car-details {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .car-details-container {
          position: relative;
          padding: 0 20px;
          &::before {
            left: 0;
            top: 0;
          }
          &::after {
            right: 0;
            top: 0;
          }
        }
        .car-details-item {
          padding: 0 10px;
          position: relative;
        }
        .car-location {
          color: #404b5a;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 14px;
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }

  // MOBILE
  .m-chosen-product {
    padding-top: 40px;
    .m-container {
      display: flex;
      flex-direction: column;
      .car-left {
        width: 100%;
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
          height: 300px;
          border-radius: 12px;
        }
        div {
          margin-top: 12px;
          display: flex;
          flex-direction: row;
          img {
            cursor: pointer;
            width: calc(100% / 4);
            height: 54px;
            border-radius: 8px;
            &:nth-child(even) {
              margin: 0 10px;
            }
          }
        }
      }
      .car-detail-main {
        margin-top: 25px;
        width: 100%;
        .car-main-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          span {
            color: #1e1e1e;
            font-family: Rubik;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          div {
            cursor: pointer;
            padding: 8px;
            display: flex;
            border-radius: 8px;
            border: 1px solid #d7e1ed;
            background: rgba(215, 225, 237, 0.2);
          }
        }
        .car-detail {
          margin-top: 24px;
          height: 153px;
          display: flex;
          flex-direction: column;
          border-radius: 16px;
          border: 1px solid #e1e5ea;
          background: #fff;
          .car-detail-info {
            height: 50%;
            display: flex;
            flex-direction: row;
            &:first-child {
              border-bottom: 1px solid #e1e5ea;
            }
            div {
              width: calc(100% / 3);
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              &:first-child {
                border-right: 1px solid #e1e5ea;
              }
              &:last-child {
                border-left: 1px solid #e1e5ea;
              }
              span {
                color: rgba(30, 30, 30, 0.4);
                font-family: Rubik;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              p {
                margin-top: 8px;
                color: #1e1e1e;
                font-family: Rubik;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
        .info {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          span {
            color: #1e1e1e;
            font-family: Rubik;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          p {
            margin-top: 10px;
            color: #1e1e1e;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 27px */
          }
        }
      }
      .contact-form {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .contact-main-form {
          padding: 24px;
          margin-top: 30px;
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          border-radius: 24px;
          border: 1px solid #d7e1ed;
          background: #fff;
          box-sizing: border-box;
          .form-box {
            margin-bottom: 25px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            .input-box {
              width: 100%;
              display: flex;
              flex-direction: column;
              span {
                color: #181d25;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
              }
              div {
                margin-top: 10px;
                padding: 8px;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 8px;
                border: 1px solid #e1e5ea;
                background: #fff;

                input {
                  margin-left: 10px;
                  width: 90%;
                  border: none;
                  outline: none;
                  color: #929fb1;
                  font-family: Inter;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                }
              }
            }
          }
          .form-send {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
              color: #929fb1;
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            button {
              margin-top: 15px;
              width: 100%;
              display: flex;
              padding: 12px;
              justify-content: space-between;
              align-items: center;
              border-radius: 8px;
              border: 1px solid #0056bb;
              background: #0056bb;
              box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2) inset,
                0px 2px 0px 0px rgba(255, 255, 255, 0.16) inset;
              color: #fff;
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              svg {
                margin-left: 8px;
              }
            }
          }
        }
      }
      .similar-cars {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        strong {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .car-img {
          width: 100%;
          height: 280px;
        }
        .car-price {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 8px;
        }
        .car-title {
          color: #606e80;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .details {
          display: flex;
          flex-direction: column;
          align-items: center;
          .car-details {
            color: #1e1e1e;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &:first-child {
              margin-bottom: 8px;
              color: rgba(30, 30, 30, 0.8);
            }
          }
        }

        .car-details-container {
          position: relative;
          padding: 0 20px;
          &::before {
            left: 0;
            top: 0;
          }
          &::after {
            right: 0;
            top: 0;
          }
        }
        .car-details-item {
          padding: 0 10px;
          position: relative;
        }
        .car-location {
          color: #404b5a;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 14px;
          display: flex;
          align-items: center;
          gap: 5px;
        }
        .car-details-container {
          &::before {
            content: "";
            width: 1px;
            height: 100%;
            background: rgba(30, 30, 30, 0.3);
            position: absolute;
          }
          &::after {
            content: "";
            width: 1px;
            height: 100%;
            background: rgba(30, 30, 30, 0.3);
            position: absolute;
          }
        }
      }
    }
  }

  .m-product-list {
    display: flex;
    flex-direction: column;
    .m-container {
      flex-direction: column;
      .filter-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        strong {
          color: #000;
          font-family: Rubik;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .filter {
          gap: 15px;
          margin-top: 10px;
          width: 100%;
          padding: 24px;
          display: flex;
          flex-direction: column;
          border-radius: 24px;
          border: 1px solid rgba(205, 217, 232, 0.8);
          background: #fff;
          div {
            display: flex;
            flex-direction: column;
            span {
              color: #181d25;
              font-family: Poppins;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 133.333% */
            }
            select {
              margin-top: 6px;
              padding: 10px;
              border-radius: 8px;
              border: 1px solid #e1e5ea;
              background: #fff;
              box-sizing: border-box;
              color: #181d25;
              font-family: Poppins;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
      }
      .product-wrap {
        display: flex;
        flex-direction: column;
        .no-data {
          padding: 30px 20px 200px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 56px;
            margin-bottom: 2px;
          }
          p {
            color: #bdbdbd;
            text-align: center;
            font-size: 26px;
            margin-top: 10px;
            font-family: Rubik;
          }
        }
        .car-img {
          width: 100%;
          height: 214px;
        }
        .car-price {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 8px;
        }
        .car-title {
          color: #606e80;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .details {
          display: flex;
          flex-direction: column;
          align-items: center;
          .car-details {
            color: #1e1e1e;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &:first-child {
              margin-bottom: 8px;
              color: rgba(30, 30, 30, 0.8);
            }
          }
        }

        .car-details-container {
          position: relative;
          padding: 0 20px;
          &::before {
            left: 0;
            top: 0;
          }
          &::after {
            right: 0;
            top: 0;
          }
        }
        .car-details-item {
          padding: 0 10px;
          position: relative;
        }
        .car-location {
          color: #404b5a;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 14px;
          display: flex;
          align-items: center;
          gap: 5px;
        }
        .car-details-container {
          &::before {
            content: "";
            width: 1px;
            height: 100%;
            background: rgba(30, 30, 30, 0.3);
            position: absolute;
          }
          &::after {
            content: "";
            width: 1px;
            height: 100%;
            background: rgba(30, 30, 30, 0.3);
            position: absolute;
          }
        }
      }
    }
  }
}
