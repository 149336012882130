.contact-page {
  padding-top: 50px;
  padding-bottom: 200px;
  background: #f8fbff;
  strong {
    color: #1e1e1e;
    font-family: Rubik;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .contact-main-form {
    padding: 24px;

    width: 695px;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    border: 1px solid #d7e1ed;
    background: #fff;
    box-sizing: border-box;
    .form-box {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .input-box {
        width: 48%;
        display: flex;
        flex-direction: column;
        span {
          color: #181d25;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
        }
        div {
          margin-top: 10px;
          padding: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #e1e5ea;
          background: #fff;

          input {
            margin-left: 10px;
            width: 90%;
            border: none;
            outline: none;
            color: #929fb1;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
          textarea {
            width: 100%;
            height: 70px;
            border: none;
            outline: none;
            color: #929fb1;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }
    }
    .form-send {
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      span {
        color: #929fb1;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      button {
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #0056bb;
        background: #0056bb;
        box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2) inset,
          0px 2px 0px 0px rgba(255, 255, 255, 0.16) inset;
        color: #fff;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        svg {
          margin-left: 8px;
        }
      }
    }
  }
  .contact-box {
    margin-left: 15px;
    padding: 40px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    border: 1px solid #e1e5ea;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(137, 137, 137, 0.12);
    .title {
      color: #181d25;
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .row-box {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span {
        color: #181d25;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &:last-child {
          text-decoration-line: underline;
        }
      }
    }
  }
}

.m-contact-frame {
  background: #f8fbff;
  padding-bottom: 100px;
  .m-container {
    flex-direction: column;
    .contact-main-form {
      padding: 24px;
      margin-top: 30px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      border: 1px solid #d7e1ed;
      background: #fff;
      box-sizing: border-box;
      .form-box {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .input-box {
          width: 100%;
          display: flex;
          flex-direction: column;
          span {
            color: #181d25;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
          }
          div {
            margin-top: 10px;
            padding: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #e1e5ea;
            background: #fff;
            input {
              margin-left: 10px;
              width: 90%;
              border: none;
              outline: none;
              color: #929fb1;
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }
            textarea {
              width: 100%;
              height: 70px;
              border: none;
              outline: none;
              color: #929fb1;
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }
          }
        }
      }
      .form-send {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          color: #929fb1;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        button {
          margin-top: 15px;
          width: 100%;
          display: flex;
          padding: 12px;
          justify-content: space-between;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #0056bb;
          background: #0056bb;
          box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2) inset,
            0px 2px 0px 0px rgba(255, 255, 255, 0.16) inset;
          color: #fff;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          svg {
            margin-left: 8px;
          }
        }
      }
    }
    .contact-box {
      margin-top: 10px;
      padding: 40px;
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      border: 1px solid #e1e5ea;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(137, 137, 137, 0.12);
      .title {
        color: #181d25;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .row-box {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
          color: #181d25;
          font-family: Poppins;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:last-child {
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}
