.about-page {
  padding-top: 50px;
  padding-bottom: 150px;
  background: #f8fbff;
  .about-top {
    padding: 34px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 16px;
    border: 1px solid #d7e1ed;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(137, 137, 137, 0.12);
    div {
      width: 50%;
      display: flex;
      flex-direction: column;
      strong {
        color: #1e1e1e;
        font-family: Rubik;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p {
        margin-top: 6px;
        color: #000;
        text-align: justify;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    img {
      width: 558px;
      height: 390px;
      border-radius: 8px;
    }
  }
  .about-center {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      width: 50%;
      display: flex;
      justify-content: center;
    }
    div.right {
      display: inline-flex;
      padding: 34px;
      flex-direction: column;
      border-radius: 16px;
      border: 1px solid #d7e1ed;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(137, 137, 137, 0.12);
      strong {
        margin-bottom: 15px;
        color: #1e1e1e;
        font-family: Rubik;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p {
        color: #000;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}

.m-about-page {
  padding-top: 50px;
  padding-bottom: 150px;
  background: #f8fbff;
  .m-container {
    flex-direction: column;
    .about-top {
      padding: 24px 16px;
      display: flex;
      border-radius: 16px;
      flex-direction: column;
      border-radius: 16px;
      border: 1px solid #d7e1ed;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(137, 137, 137, 0.12);
      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        strong {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 36px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          margin-top: 6px;
          color: #000;
          text-align: justify;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      img {
        margin-top: 16px;
        width: 100%;
        height: 322px;
        border-radius: 8px;
      }
    }
    .about-center {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      div.right {
        margin-top: 24px;
        display: flex;
        padding: 34px;
        flex-direction: column;
        border-radius: 16px;
        border: 1px solid #d7e1ed;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(137, 137, 137, 0.12);
        strong {
          margin-bottom: 15px;
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 36px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: #000;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }
}
