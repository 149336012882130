.homepage {
  padding-bottom: 100px;
  background: #f8fbff;
  .products-container {
    position: relative;
    top: -60px;
    .filter-box {
      padding: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 24px;
      border: 1px solid rgba(205, 217, 232, 0.8);
      background: #fff;
      .filter {
        display: flex;
        width: 240px;
        flex-direction: column;
        span {
          display: flex;
          padding-bottom: 6px;
          color: #181d25;
          font-family: Poppins;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
        }
        select {
          padding: 10px;
          border-radius: 8px;
          border: 1px solid #e1e5ea;
          background: #fff;
          box-sizing: border-box;
          color: #181d25;
          font-family: Poppins;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
    .car-box-img {
      width: 280px;
      height: 240px;
      border-radius: 10px;
      cursor: pointer;
    }
    .car-price {
      color: #1e1e1e;
      font-family: Rubik;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
    }
    .car-title {
      color: #606e80;
      font-family: Rubik;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .car-details {
      color: #1e1e1e;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .car-details-container {
      position: relative;
      padding: 0 20px;
      &::before {
        left: 0;
        top: 0;
      }
      &::after {
        right: 0;
        top: 0;
      }
    }
    .car-details-item {
      padding: 0 10px;
      position: relative;
    }
    .car-location {
      color: #404b5a;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 14px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .service-frame {
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .bottom {
      position: relative;
      margin-top: 45px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .info {
        position: absolute;
        left: 0;
        top: 60px;
        max-width: 672px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        border: 1px solid #e1e5ea;
        background: #fff;
        span {
          color: #000;
          font-family: Rubik;
          font-size: 23px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          margin-top: 15px;
          color: #000;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      .img {
        width: 738px;
        height: 390px;
        border-radius: 30px;
        img {
          width: 100%;
          height: 473px;
        }
      }
    }
  }

  .adv-frame {
    position: relative;
    margin-top: 100px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .adv-main {
      position: relative;
      .adv-card {
        margin-top: 30px;
        height: 120px;
        position: relative;
        display: flex;
        flex-direction: column;
        &.right {
          align-items: flex-end;
        }
        &.center {
          align-items: center;
        }
        .bg-img {
          width: 554px;
          display: flex;
          &.right {
            justify-content: flex-end;
          }
          &.center {
            width: 764px;
          }
          img {
            position: relative;
            width: 50px;
            height: 50px;
            &.bottom {
              top: 90px;
            }
          }
        }
        .main {
          position: relative;
          bottom: 32px;
          left: 15px;
          display: flex;
          padding: 24px;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          border: 1px solid #d7e1ed;
          background: rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(7.5px);
          color: #000;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          &.right {
            left: -15px;
          }
        }
      }
    }
  }

  .contact-frame {
    position: relative;
    margin-top: 100px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .contact-top {
      margin-top: 45px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div {
        width: 390px;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 76px;
          height: 76px;
          margin-right: 18px;
        }
        p {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .contact-car-img {
      margin: 35px 0 100px 0;
      border-radius: 16px;
    }
    .contact-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        color: #1e1e1e;
        font-family: Rubik;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .contact-main-form {
        padding: 24px;
        margin-top: 30px;
        width: 695px;
        height: auto;
        display: flex;
        flex-direction: column;
        border-radius: 24px;
        border: 1px solid #d7e1ed;
        background: #fff;
        box-sizing: border-box;
        .form-box {
          margin-bottom: 25px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .input-box {
            width: 48%;
            display: flex;
            flex-direction: column;
            span {
              color: #181d25;
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
            }
            div {
              margin-top: 10px;
              padding: 8px;
              display: flex;
              flex-direction: row;
              align-items: center;
              border-radius: 8px;
              border: 1px solid #e1e5ea;
              background: #fff;

              input {
                margin-left: 10px;
                width: 90%;
                border: none;
                outline: none;
                color: #929fb1;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
              }
              textarea {
                width: 100%;
                height: 70px;
                border: none;
                outline: none;
                color: #929fb1;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
              }
            }
          }
        }
        .form-send {
          margin-bottom: 30px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          span {
            color: #929fb1;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          button {
            display: flex;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #0056bb;
            background: #0056bb;
            box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2) inset,
              0px 2px 0px 0px rgba(255, 255, 255, 0.16) inset;
            color: #fff;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            svg {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  .faq-frame {
    position: relative;
    margin-top: 100px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      color: #1e1e1e;
      font-family: Rubik;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .main-title {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    img {
      margin-left: 10px;
    }
  }
}

.m-homepage {
  background: #f8fbff;
  padding-bottom: 100px;
  .products-frame {
    position: relative;
    top: -140px;
    .m-container {
      display: flex;
      flex-direction: column;
      .filter-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        strong {
          color: #fff;
          font-family: Rubik;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 90px;
        }
        .filter {
          gap: 15px;
          margin-top: 10px;
          width: 100%;
          padding: 24px;
          display: flex;
          flex-direction: column;
          border-radius: 24px;
          border: 1px solid rgba(205, 217, 232, 0.8);
          // background: #fff;
          background: #f0f9f9;
          div {
            display: flex;
            flex-direction: column;
            span {
              color: #181d25;
              font-family: Poppins;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 133.333% */
            }
            select {
              margin-top: 6px;
              padding: 10px;
              border-radius: 8px;
              border: 1px solid #e1e5ea;
              background: #fff;
              box-sizing: border-box;
              color: #181d25;
              font-family: Poppins;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
      }
      .product-wrap {
        display: flex;
        flex-direction: column;
        .car-img {
          width: 100%;
          height: 280px;
        }
        .car-price {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 8px;
        }
        .car-title {
          color: #606e80;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .details {
          display: flex;
          flex-direction: column;
          align-items: center;
          .car-details {
            color: #1e1e1e;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &:first-child {
              margin-bottom: 8px;
              color: rgba(30, 30, 30, 0.8);
            }
          }
        }

        .car-details-container {
          position: relative;
          padding: 0 20px;
          &::before {
            left: 0;
            top: 0;
          }
          &::after {
            right: 0;
            top: 0;
          }
        }
        .car-details-item {
          padding: 0 10px;
          position: relative;
        }
        .car-location {
          color: #404b5a;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 14px;
          display: flex;
          align-items: center;
          gap: 5px;
        }
        .car-details-container {
          &::before {
            content: "";
            width: 1px;
            height: 100%;
            background: rgba(30, 30, 30, 0.3);
            position: absolute;
          }
          &::after {
            content: "";
            width: 1px;
            height: 100%;
            background: rgba(30, 30, 30, 0.3);
            position: absolute;
          }
        }
      }
    }
  }

  .adv-frame {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .m-container {
      display: flex;
      flex-direction: column;
      .adv-main {
        position: relative;
        width: 100%;
        .adv-card {
          margin-top: 15px;
          position: relative;
          display: flex;
          flex-direction: column;
          .main {
            width: 100%;
            position: relative;
            display: flex;
            padding: 24px;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            border: 1px solid #d7e1ed;
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(7.5px);
            color: #000;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
        .bg-img {
          position: absolute;
          width: 100%;
          &.one {
            width: 48px;
            height: 48px;
            left: -18px;
          }
          &.two {
            width: 48px;
            height: 48px;
            right: -18px;
            top: 130px;
          }
          &.three {
            width: 87px;
            height: 87px;
            left: -18px;
            bottom: 0;
          }
        }
      }
    }
  }

  .contact-frame {
    position: relative;
    margin-top: 100px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .m-container {
      flex-direction: column;
      .contact-top {
        display: flex;
        flex-direction: column;
        div {
          margin-top: 16px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 60px;
            height: 60px;
            margin-right: 18px;
          }
          p {
            color: #1e1e1e;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .contact-car-img {
        width: 100%;
        height: 165px;
        margin: 35px 0 50px 0;
        border-radius: 16px;
      }
      .contact-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          color: #1e1e1e;
          font-family: Rubik;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .contact-main-form {
          padding: 24px;
          margin-top: 30px;
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          border-radius: 24px;
          border: 1px solid #d7e1ed;
          background: #fff;
          box-sizing: border-box;
          .form-box {
            margin-bottom: 25px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            .input-box {
              width: 100%;
              display: flex;
              flex-direction: column;
              span {
                color: #181d25;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
              }
              div {
                margin-top: 10px;
                padding: 8px;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 8px;
                border: 1px solid #e1e5ea;
                background: #fff;

                input {
                  margin-left: 10px;
                  width: 90%;
                  border: none;
                  outline: none;
                  color: #929fb1;
                  font-family: Inter;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                }
                textarea {
                  width: 100%;
                  height: 70px;
                  border: none;
                  outline: none;
                  color: #929fb1;
                  font-family: Inter;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                }
              }
            }
          }
          .form-send {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
              color: #929fb1;
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            button {
              margin-top: 15px;
              width: 100%;
              display: flex;
              padding: 12px;
              justify-content: space-between;
              align-items: center;
              border-radius: 8px;
              border: 1px solid #0056bb;
              background: #0056bb;
              box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2) inset,
                0px 2px 0px 0px rgba(255, 255, 255, 0.16) inset;
              color: #fff;
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              svg {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }

  .faq-frame {
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .m-container {
      flex-direction: column;
      .faq-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        display: flex;
        width: 100%;
        padding: 16px;
        border-radius: 16px;
        border: 1px solid #e1e5ea;
        background: #fff;
        .title {
          color: #000;
          text-align: center;
          font-family: Rubik;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
        * {
          font-family: Rubik;
        }
      }
    }
  }

  .main-title {
    justify-content: center;
    align-items: center;
    color: #1e1e1e;
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    img {
      width: 34px;
      margin-left: 10px;
    }
  }
}
