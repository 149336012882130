.service-page {
  padding-top: 50px;
  padding-bottom: 150px;
  background: #f8fbff;
  .service-box {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .box {
      width: 65%;
      position: relative;
      display: flex;
      img {
        width: 60px;
        height: 60px;
      }
      div {
        position: absolute;
        top: 25px;
        left: 25px;
        display: flex;
        flex-direction: column;
        padding: 24px;
        border-radius: 12px;
        border: 1px solid #d7e1ed;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(7.5px);
        span {
          color: #000;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
        p {
          margin-top: 8px;
          color: #000;
          font-family: Rubik;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      &.right {
        justify-content: flex-end;
        div {
          left: 0;
          right: 25px;
        }
      }
    }
  }
}

.m-service-page {
  background: #f8fbff;
  padding-bottom: 100px;
  .m-container {
    flex-direction: column;
    .service-box {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .main-img {
        width: 60%;
        height: 180px;
      }
      .box {
        width: 100%;
        display: flex;
        flex-direction: column;
        &.right {
          img {
            position: absolute;
            width: 60px;
            height: 60px;
            right: 0;
          }
        }
        img {
          position: absolute;
          width: 60px;
          height: 60px;
        }
        div {
          margin-top: 20px;
          padding: 24px;
          border-radius: 12px;
          border: 1px solid #d7e1ed;
          background: rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(7.5px);

          span {
            color: #000;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
          p {
            margin-top: 8px;
            color: #000;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }
    }
  }
}
