.footer-frame {
  padding: 40px 0;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #c3ced7;
  background: #fff;
  span {
    color: #606e80;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  img {
    margin-left: 30px;
    width: 40px;
    height: 40px;
  }
}

.m-footer-frame {
  padding: 40px 0;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #c3ced7;
  background: #fff;
  .m-container {
    flex-direction: column;
    span {
      margin-bottom: 24px;
      color: #606e80;
      text-align: center;
      font-family: Rubik;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    img {
      margin: 0 15px;
      width: 40px;
      height: 40px;
    }
  }
}
