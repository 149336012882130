.login-page {
  position: relative;
  padding-top: 100px;
  padding-bottom: 200px;
  height: 100vh;
  display: flex;
  align-items: center;
  background: #f8fbff;
  h1 {
    font-size: 36px;
    font-weight: 700;
    font-family: Rubik;
    line-height: 1.167;
  }
  p {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 14px;
    font-weight: 400;
    font-family: Rubik;
    text-align: center;
  }
}
