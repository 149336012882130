@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import "../scss/variables.scss";
@import "../scss/footer.scss";
@import "../scss/navbar.scss";
@import "../scss/home.scss";
@import "../scss/products.scss";
@import "../scss/admin.scss";
@import "../scss/contact.scss";
@import "../scss/about.scss";
@import "../scss/service.scss";
@import "../scss/login.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.lan-box {
  display: flex;
  align-items: center;
  .notification-icon {
    color: white;
  }
  .btn-lang {
    border-radius: 4px;
    img {
      width: 24px;
      height: 17px;
      border-radius: 2px;
    }
  }
  .img-flag {
    width: 24px;
    height: 17px;
    border-radius: 2px;
    margin-right: 8px;
  }
}

.img-flag {
  width: 24px;
  height: 17px;
  border-radius: 2px;
  margin-right: 8px;
}

.m-container {
  padding: 0px 16px;
  width: 100%;
  height: auto;
  display: flex;
}
